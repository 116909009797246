<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
        <v-select
          style="z-index: 1000;  margin-top: 4%; margin: 10px;"
          class="w-full"
          placeholder="Filters"
          v-model="select1"
          :options="options1"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <vs-dropdown class="gap" vs-custom-content vs-trigger-click style="margin-right: 1%">
          <vs-button radius color="primary" icon="search"></vs-button>
          <vs-dropdown-menu class="loginx">
            <vs-row vs-w="12" style="margin-bottom: 2%">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                <vs-input type="text" label-placeholder="Name" v-model="value1" />
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                <vs-button color="dark" type="gradient" icon="search" @click="search('name')"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row vs-w="12" style="margin-bottom: 2%">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                <vs-input type="text" label-placeholder="Can.No" v-model="value2" />
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                <vs-button
                  color="dark"
                  type="gradient"
                  icon="search"
                  @click="search('candidate_id')"
                ></vs-button>
              </vs-col>
            </vs-row>
            <vs-row vs-w="12" style="margin-bottom: 2%">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                <vs-input type="text" label-placeholder="company" v-model="value3" />
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                <vs-button color="dark" type="gradient" icon="search" @click="search('company')"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row vs-w="12" style="margin-bottom: 2%">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                <vs-input type="text" label-placeholder="Mobile" v-model="value4" />
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                <vs-button color="dark" type="gradient" icon="search" @click="search('mobile')"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row vs-w="12" style="margin-bottom: 2%">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                <vs-input type="text" label-placeholder="Email" v-model="value5" />
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                <vs-button color="dark" type="gradient" icon="search" @click="search('email')"></vs-button>
              </vs-col>
            </vs-row>
          </vs-dropdown-menu>
        </vs-dropdown>
        <!-- <vs-button radius class="gap" color="primary"  type="gradient" icon="refresh" @click="$store.commit('SORT_MWB_LEADS', [])"></vs-button> -->
      </vs-col>
    </vs-row>
    <vs-table max-items="10" :data="accounts">
      <template slot="thead">
        <vs-th>STATUS</vs-th>
        <vs-th sort-key="id"> Can-ID</vs-th>
        <vs-th sort-key="candidate_name">NAME</vs-th>
        <vs-th sort-key="batch_name">BATCH</vs-th>
        <vs-th sort-key="id">ENROLLMENT DATE</vs-th>
        <vs-th sort-key="id">AGREED</vs-th>
        <vs-th sort-key="id">PAID</vs-th>
        <vs-th sort-key="id">DUE</vs-th>
        <vs-th sort-key="id">DUE%</vs-th>
        <vs-th>ACTIONS</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <vs-chip :color="feesColor(tr.fees_status)">
                <b>$</b>
              </vs-chip>
            </vs-td>
            <vs-td>
              <div>{{tr.candidate_id}}</div>
            </vs-td>
            <vs-td>{{tr.candidate_name}}</vs-td>
            <vs-td>
              <div>{{tr.batch}}</div>
            </vs-td>
            <vs-td>
              <div>{{tr.Enrollment_Date}}</div>
            </vs-td>

            <vs-td>{{tr.Fee_Agreed}}</vs-td>

            <vs-td>
              <div>{{ tr.Fee_Paid }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.Fees_Due }}</div>
            </vs-td>

            <vs-td>
              <div>{{getWholePercent(tr.Fees_Due , tr.Fee_Agreed)}}</div>
            </vs-td>

            <vs-td>
              <vs-row>
                <vs-button
                  :style="{visibility: buttoncolor(tr.fees_status)}"
                  size="small"
                  color="success"
                  type="gradient"
                  @click="openPopupEmit(tr)"
                >Update</vs-button>
                <vs-button
                  :style="{visibility: buttoncolor2(tr.fees_status)}"
                  size="small"
                  color="danger"
                  type="gradient"
                  @click="openPopupDeafultEmit(tr)"
                >Defaulter</vs-button>
              </vs-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-pagination :total="10" v-model="currentTablePage"></vs-pagination>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import vSelect from "vue-select";
import EventBus from "../components/eventbus.js";

export default {
  data() {
    return {
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      expand: false,
      toggleDefaulter: false,
      toggleUpdate: false,
      currentTablePage: 1,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      options1: [
        { id: 1, label: "All" },
        { id: 2, label: "Cleared" },
        { id: 3, label: "Default" },
        { id: 4, label: "Partial" }
      ],
      select1: "",
      accounts: [],
      accountsRaw: []
    };
  },
  watch: {
    currentTablePage: function(val) {
      console.log(`API call for the ${val} pagination link`);
    },
    select1: function(value) {
      this.accounts = [];
      if (value === "All") {
        this.$store.state.fees_books.forEach(fees => {
          this.accounts.push(fees);
        });
      } else {
        this.$store.state.fees_books.forEach(fees => {
          if (fees.fees_status === value) {
            this.accounts.push(fees);
          }
        });
      }
    }
  },
  methods: {
    search(type) {
      this.accounts = [];
      switch (type) {
        case "name":
          this.accountsRaw.forEach(raw => {
            if (raw.candidate_name === this.value1) {
              this.accounts.push(raw);
            }
            this.value2 = "";
            this.value3 = "";
            this.value4 = "";
            this.value5 = "";
          });
          break;
        case "candidate_id":
          this.$store.state.allCandidatesRaw.forEach(candidate => {
            if (candidate.candidate_number === parseInt(this.value2)) {
              this.accountsRaw.forEach(raw => {
                if (raw.candidate_id === candidate.id) {
                  this.accounts.push(raw);
                }
              });
            }
            this.value1 = "";
            this.value3 = "";
            this.value4 = "";
            this.value5 = "";
          });
          break;
        case "company":
          this.$store.state.allCandidatesRaw.forEach(candidate => {
            if (candidate.company === this.value3) {
              this.accountsRaw.forEach(raw => {
                if (raw.candidate_id === candidate.id) {
                  this.accounts.push(raw);
                }
              });
            }
            this.value1 = "";
            this.value2 = "";
            this.value4 = "";
            this.value5 = "";
          });
          break;
        case "mobile":
          // console.log(mobile)
          this.$store.state.allCandidatesRaw.forEach(candidate => {
            candidate.mobile.forEach(candidatemobile => {
              if (candidatemobile === parseInt(this.value4)) {
                this.accountsRaw.forEach(raw => {
                  if (raw.candidate_id === candidate.id) {
                    this.accounts.push(raw);
                  }
                });
              }
            });
            this.value1 = "";
            this.value2 = "";
            this.value3 = "";
            this.value5 = "";
          });
          break;
        case "email":
          this.$store.state.allCandidatesRaw.forEach(candidate => {
            candidate.email.forEach(candidateemail => {
              if (candidateemail === this.value5) {
                this.accountsRaw.forEach(raw => {
                  if (raw.candidate_id === candidate.id) {
                    this.accounts.push(raw);
                  }
                });
              }
            });
            this.value1 = "";
            this.value2 = "";
            this.value3 = "";
            this.value4 = "";
          });
      }
      // this.value1 = ""
      // this.value2 = ""
      // this.value3 = ""
      // this.value4 = ""
      // this.value5 = ""
    },
    clear() {
      this.value1 = "";
      this.value2 = "";
    },
    openPopupEmit(payload) {
      EventBus.$emit("open-account-popup", payload);
    },
    openPopupDeafultEmit(tr) {
      // console.log(tr)
      //   if (tr.fees_status === 'Default') {
      //   return 'danger'
      //   }
      tr.fees_status = "Default";
      // return 'danger'
    },
    getWholePercent(percentFor, percentOf) {
      percentFor = percentFor.replace(/,/g, "");
      percentFor = percentFor.replace(/₹/g, "");
      percentFor = parseInt(percentFor);
      percentOf = percentOf.replace(/,/g, "");
      percentOf = percentOf.replace(/₹/g, "");
      percentOf = parseInt(percentOf);
      let finalPercentage = Math.floor((percentFor / percentOf) * 100);
      let string = "(" + finalPercentage + "%)";

      return string;
    },
    feesColor(Fees_Status) {
      switch (Fees_Status) {
        case "Cleared":
          return "success";
        case "Default":
          return "danger";
        case "Partial":
          return "warning";
        default:
          return "danger";
      }
    },
    buttoncolor(Fees_status) {
      switch (Fees_status) {
        case "Cleared":
          return "hidden";
        case "Default":
          return "";
        case "Partial":
          return "";
      }
    },
    buttoncolor2(Fees_status) {
      switch (Fees_status) {
        case "Cleared":
          return "hidden";
        case "Default":
          return "hidden";
        case "Partial":
          return "";
      }
    }
  },
  components: {
    "v-select": vSelect
  },
  mounted() {
    this.$store.state.fees_books.forEach(tab => {
      this.accounts.push(tab);
      this.accountsRaw.push(tab);
    });
  }
};
</script>

<style>
.vs-radar-text {
  color: darkgray;
  position: relative;
  bottom: 70px;
  left: 35px;
  font-size: larger;
}
.radial_quarter {
  position: relative;
  height: 260px;
}
.lineAreaChartSpline {
  position: relative;
  height: 260px;
}
.vs-radar-thisquarter {
  color: darkgray;
  position: relative;
  bottom: 70px;
  font-size: larger;
  text-align: center;
}
.input-search {
  position: relative;
  right: 10px;
}
.table_heading {
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr {
  /* background-color:#ffbc00; */
  font-family: sans-serif;
  font-size: smaller;
  /* border: 1px solid black; */
}
</style>
